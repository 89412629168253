import Vue from 'vue'
import VueRouter from 'vue-router'
import WaitingRoom from '../views/WaitingRoom.vue'
import App from '../App.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/telmed',
    name: 'Telekonsultasi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Telekonsultasi.vue')
  },
  {
    path: '/404',
    name: 'notFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  },
  {
    path: '/checkPermission',
    name: 'checkPermission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckPermission.vue')
  },
  {
    path: '/endCall',
    name: 'endCall',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EndCall.vue')
  },
  {
    path: '/waitingRoom',
    name: 'waitingRoom',
    component: WaitingRoom,
  },
  {
    path: '/waiting',
    redirect: '/checkPermission',
    name: 'waiting',
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/TncPage.vue')
  },
  {
    path: '*',
    name: 'home',
    redirect: { name: 'notFound' }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
