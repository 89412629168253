<template>
  <div class="home sWaiting">
    <div class="absolute-top-0-right-0 badge badge-secondary">
      <div class="status-circle" :class="connection.cssClass"></div>
      <span>{{ connection.text }}</span>
    </div>
    <div v-if="passIncluded">
      <div class="row waiting-top-row ml-1">
        <div class="col">
          <div class="float-left row">
            <img
              src="./../assets/care_dokter_logo.png"
              alt=""
              class="mr-3 tel-log"
              style="height: 65px"
            />
            <h4 class="mt-3 telTitle">TELEMEDICINE</h4>
          </div>
        </div>

        <div class="col">
          <div
            class="mb-5 text-right mr-5"
            v-if="keterangan.reservasi"
            id="ketTop"
          >
            <h4>{{ keterangan.reservasi.jadwal.dokter.nama }}</h4>
            <h4>{{ keterangan.reservasi.jadwal.dokter.tujuanRujukan ? keterangan.reservasi.jadwal.dokter.tujuanRujukan.namaIndo : '-' }}</h4>
            <h4>
              {{ keterangan.reservasi.jadwal.hari }}
              {{ keterangan.reservasi.tanggalKunjungan }}
            </h4>
            <h4>
              {{ keterangan.reservasi.jamMulai }} -
              {{ keterangan.reservasi.jamSelesai }}
            </h4>
          </div>
        </div>
      </div>

      <!-- v-if="passIncluded && waiting" -->
      <div class="container">
        <div align="center">
          <h3>Join Sebagai:</h3>
          <h3 class="mt-3" v-if="keterangan.reservasi">
            {{
              $store.state.roleDokter
                ? keterangan.reservasi.jadwal.dokter.nama
                : keterangan.reservasi.patient.patientName
            }}
          </h3>
          <h3>{{ $store.state.roleDokter ? "(Dokter)" : "(Pasien)" }}</h3>
        </div>

        <div v-if="userReady">
          <h2 class="font-weight-bold text-primary">Sudah Tersedia</h2>
          <br />
          <button
            type="button"
            class="btn btn-primary btn-msk"
            @click="$router.push('/telmed')"
          >
            Klik Untuk Masuk
          </button>
        </div>
        <br />

        <!-- ask if ready -->
        <div v-if="loading" class="mb-4">
          <h3>
            Menunggu {{ $store.state.roleDokter ? "Pasien" : "Dokter" }} Untuk
            Join Ke Ruangan
          </h3>
          <br />
          <button
            class="btn btn-primary btn-msk"
            type="button"
            style="font-size: 25px"
            disabled
          >
            <span
              class="spinner-border"
              style="width: 2rem; height: 2rem"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        </div>
        <div v-if="askReady && !loading">
          <h3 class="font-weight-bold text-primary">Join Telekonsul?</h3>
          <br />
          <button
            type="button"
            class="center btn btn-primary btn-msk"
            @click="initSocket()"
          >
            Masuk Sekarang
          </button>
        </div>

        <div class="mt-4" v-if="keterangan.reservasi" id="ketBottom">
          <h4>{{ keterangan.reservasi.jadwal.dokter.nama }}</h4>
          <h4>{{ keterangan.reservasi.jadwal.dokter.tujuanRujukan ? keterangan.reservasi.jadwal.dokter.tujuanRujukan.namaIndo : '-' }}</h4>
          <h4>
            {{ keterangan.reservasi.jadwal.hari }}
            {{ keterangan.reservasi.tanggalKunjungan }}
          </h4>
          <h4>
            {{ keterangan.reservasi.jamMulai }} -
            {{ keterangan.reservasi.jamSelesai }}
          </h4>
        </div>
        <br />
      </div>
    </div>

    <!-- link pasien -->
    <div class="mt-4" align="center" v-if="$store.state.roleDokter">
      <h4>Pasien Belum Masuk? Share Link Ini Ke Pasien Anda:</h4>

      <div
        class=""
        style="background-color: white; max-width: 300px; border-radius: 20px"
      >
        <span
          class="d-inline-block text-truncate align-middle"
          style="max-width: 200px"
        >
          {{ linkPasien }}
        </span>
        <button
          type="button"
          class="btn btn-default btn-copy js-tooltip js-copy"
          data-toggle="tooltip"
          data-placement="bottom"
          :data-copy="linkPasien"
          title="Copy to clipboard"
        >
          <i class="icon fas fa-copy text-dark"></i>
        </button>
      </div>
    </div>

    <div class="container" v-if="!passIncluded">
      <div class="card text-center">
        <div class="card-header">Please Input Password</div>

        <div class="card-body">
          <form>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input
                type="password"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                v-model="inputPassword"
                required
              />
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="verifyPass"
            >
              Submit
            </button>
          </form>
        </div>

        <div class="card-footer text-muted"></div>
      </div>
    </div>
    <a class="badge badge-primary footer-tnc" href="/terms-and-conditions">
      Syarat dan Ketentuan</a
    >
  </div>
</template>

<script>
import axios from "axios";
import ModalPassword from "../components/ModalPassword";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import FlipCountdown from "vue2-flip-countdown";
import { JSEncrypt } from "jsencrypt";
export default {
  name: "WaitingRoom",
  data() {
    return {
      intervalConnection: "",
      connection: {
        text: "",
        cssClass: "",
      },
      isPass: false,
      params: {},
      keterangan: {},
      roleDokter: false,
      stompClient: null,
      userReady: false,
      inputPassword: null,
      waiting: false,
      passIncluded: false,
      inputPassword: null,
      early: false,
      // timeLeft: "",
      sesiExpired: false,
      readyToRoom: false,
      askReady: false,
      loading: false,
      timeout: 5000,
      linkPasien: "",
      isFirstTime: true,
      status: [
        { text: "Idle", cssClass: "status-idle" },
        {
          text: "Waiting to connect...",
          cssClass: "status-wsconnecting blink-me",
        },
        {
          text: "Connection failed, retrying..",
          cssClass: "status-wsconnfailed",
        },
        {
          text: "Connection successfull, requesting session..",
          cssClass: "status-wsconnsuccess",
        },
        {
          text: "Waiting for participant..",
          cssClass: "status-waitingready blink-me",
        },
        {
          text: "Participants are ready, preparing room..",
          cssClass: "status-allready",
        },
        { text: "Error Key assignee", cssClass: "status-wsconnfailed" },
      ],
    };
  },
  components: {
    ModalPassword,
    FlipCountdown,
  },
  methods: {
    parseTime(time) {
      let c = time.split(":");
      // console.log(parseInt(c[0]) * 60 + rpaseInt(c[1]));
      return parseInt(c[0]) * 60 + parseInt(c[1]);
    },
    verifyPass() {
      // rsa key here
      // this.params.password = inputPassword;
      // New JSEncrypt object
      let encryptor = new JSEncrypt();
      // Setting public key
      encryptor.setPublicKey(
        "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAK+i+DCC0/gHMonvl0SfsGwHJnsXJvDIY3xeKdR1VHTxr4lFGt/eMq6sQ40cSdyObW/c3fjnzkZIT/eo8Xy0+jMCAwEAAQ=="
      );
      // Encrypted data
      this.params.password = encryptor.encrypt(this.inputPassword);

      if (this.inputPassword) {
        this.passIncluded = true;
        this.getToken();
      }
    },
    getPrinterRepository() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/api/back-office-environment`, {
          headers: {
            "X-TenantId": this.params.tenantId,
          },
        })
        .then((data) => {})
        .catch((err) => {
          console.log("getData", err);
        });
    },
    async failedSession() {
      this.connection = this.status[5];
      // this.$vToastify.info("refreshing token");
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/telemedicine/api/complete-task/failed-create-opentok-session`,
          "",
          {
            headers: {
              "X-TenantId": this.params.tenantId,
            },
            params: {
              assigneeId: this.params.linkId,
            },
          }
        )
        .then(() => {
          this.connection = this.status[5];
          // this.getToken();
          // this.initSocket();
        })
        .catch(() => {
          this.connection = this.status[6];
        });
    },
    getSpesialis(items) {
      const index = items.findIndex(item => item.default)
      if(index > -1) {
        return items[index].spesialisasi
      }
      return {
        nama: '-'
      }
    },
    async getToken() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/api/teleconsult-room`, {
          headers: {
            "X-TenantId": this.params.tenantId,
          },
          params: this.params,
        })
        .then(({ data }) => {
          if (!this.isFirstTime) {
            this.connection = this.status[4];
          }
          const mapperDokter = data.reservasi.jadwal.dokter
          data.reservasi.jadwal.dokter.tujuanRujukan = this.getSpesialis(mapperDokter.dokterSpesialisasis)
          this.keterangan = data;
          this.$store.commit("SET_TELE_DATA",  this.keterangan);

          // handle disini utk session null
          // pengecekan sudah selesai apa belum
          if (
            !data.sessionId &&
            data.doctorReadyAt &&
            data.patientReadyAt &&
            data.doctorLeaveAt &&
            data.patientLeaveAt
          ) {
            // telekonsul selesai
            this.$router.push("/endCall");
          } else if (
            (data.accessToken || data.guestAccessToken) &&
            !data.sessionId
          ) {
            this.$router.push("/endCall");
          } else if (
            data.doctorReadyAt &&
            data.patientReadyAt &&
            !data.sessionId &&
            !this.isFirstTime
          ) {
            // ketika pasien siap & dokter siap tetapi sesion id tidak ada
            this.failedSession();
          } else if (!data.sessionId) {
            // telekonsul baru
            this.askReady = true;
          } else {
            // sessionId = true
            clearInterval(this.intervalConnection);
            this.askReady = false;
            this.loading = true;
            let telfo = JSON.parse(localStorage.getItem("telmedinfo"));
            if (!this.$store.state.accessToken) {
              this.$store.commit("SET_TELE_DATA", telfo);
            }
            setTimeout(() => {
              this.$router.push("/telmed");
            }, 3000);
          }
        })
        .catch(() => {
          this.connection = this.status[6];
          // this.$vToastify.error(
          //   err.response.data.title,
          //   err.response.data.errorKey
          // );
        });
      // .finally(() => {
      // this.timeLeft = `${this.keterangan.reservasi.tanggalKunjungan} ${this.keterangan.reservasi.jamMulai}:00`;
      // });
    },
    initSocket() {
      this.connection = this.status[1];
      this.loading = true;
      this.isFirstTime = false;
      // let str = Object.entries(this.params)
      //   .map(([key, val]) => key + "=" + val + "")
      //   .join("&");
      let urlParams = new URL(
        `${process.env.VUE_APP_BASE_URL}/websocket/teleconsult/room`
      );
      for (let i in this.params) {
        if (this.params.hasOwnProperty(i)) {
          urlParams.searchParams.append(i, this.params[i]);
        }
      }

      // let base = process.env.VUE_APP_MIX_WEB_SOCKET;
      var socket = new SockJS(urlParams);
      this.stompClient = Stomp.over(socket);
      const that = this;
      this.stompClient.debug = () => {};
      this.stompClient.connect(
        {},
        (frame) => {
          this.connection = this.status[3];
          this.subsSocket();
          console.log("frameeee -->", frame);
        },
        (error) => {
          this.connection = this.status[2];
          //
          // console.log(error)
          setTimeout(() => {
            if (!that.stompClient.connected) {
              that.stompClient.disconnect();
              // this.readyWaiting()
              that.initSocket();
              // that.$vToastify.info("Mencoba menyambungkan kembali");
            } else {
              console.log("connect , dan menunggu update websocket");
            }
          }, 5000);
          console.log("Reconnect WebStomp", error);
        }
      );
    },
    objectDecode(item) {
      let newObject = {};
      const amp = "amp;";
      Object.keys(item).forEach(function (key) {
        const value = item[key];
        if (key.includes(amp)) {
          key.replace(amp, "");
        }

        newObject[key] = decodeURIComponent(value);
      });
      return newObject;
    },
    subsSocket() {
      // ketika gadapet2 socket
      this.intervalConnection = setInterval(() => {
        this.getToken();
      }, this.timeout);
      this.stompClient.subscribe(
        `/topic/teleconsult/room/${encodeURIComponent(this.params.linkId)}`,
        (tick) => {
          let msg = JSON.parse(tick.body);
          if (tick) {
            this.getToken();
          }
        },
        (Headers = {
          password: `${encodeURIComponent(this.params.password)}`,
        })
      );
      // }
    },
  },
  beforeMount() {
    let param = sessionStorage.getItem("permission");
    if (!param) {
      this.$router.push(`waiting?${this.$route.fullPath.substring(13)}`);
    }
  },
  mounted() {
    this.connection = this.status[0];
    let paspas = this.$route.query.password;
    if (!paspas) {
      paspas = this.$route.query["amp;password"];
    }

    var ua = navigator.userAgent;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var x = document.getElementsByTagName("BODY")[0];
    if (isSafari) {
      x.classList.add("b-mobile");
      if (/iPhone|iPod/i.test(ua)) {
        x.classList.add("iphone");
      }
    } else if (
      /Android|webOS|Tablet|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(
        ua
      )
    ) {
      x.classList.add("b-mobile");
    } else /Chrome/i.test(ua);

    localStorage.setItem("linkParams", this.$route.fullPath);
    this.params = this.$route.query;
    this.params = this.objectDecode(this.params);
    this.linkPasien = `${window.location.origin}/waiting?`;
    this.linkPasien += Object.entries(this.params)
      .map(([key, val]) =>
        key !== "key" ? key + "=" + encodeURIComponent(val) + "" : ""
      )
      .join("&");
    this.$store.commit("SET_LINK_PASIEN", this.linkPasien);
    this.timeout = parseInt(process.env.VUE_APP_TIMEOUT);

    if (paspas) {
      this.passIncluded = true;
      this.getToken();
    } else {
      this.$vToastify.info("please input the right one", "Password needed");
    }
    if (this.params.key) {
      this.roleDokter = true;
      this.$store.commit("SET_ROLE_DOKTER", true);
    }

    // copy
    function copyToClipboard(text, el) {
      var copyTest = document.queryCommandSupported("copy");
      var elOriginalText = el.attr("data-original-title");

      if (copyTest === true) {
        var copyTextArea = document.createElement("textarea");
        copyTextArea.value = text;
        document.body.appendChild(copyTextArea);
        copyTextArea.select();
        try {
          var successful = document.execCommand("copy");
          var msg = successful ? "Copied!" : "Whoops, not copied!";
          el.attr("data-original-title", msg).tooltip("show");
        } catch (err) {
          console.log("Oops, unable to copy");
        }
        document.body.removeChild(copyTextArea);
        el.attr("data-original-title", elOriginalText);
      } else {
        // Fallback if browser doesn't support .execCommand('copy')
        window.prompt("Copy to clipboard: Ctrl+C or Command+C, Enter", text);
      }
    }

    $(document).ready(function () {
      // Initialize
      // ---------------------------------------------------------------------

      // Tooltips
      // Requires Bootstrap 3 for functionality
      $(".js-tooltip").tooltip();

      // Copy to clipboard
      // Grab any text in the attribute 'data-copy' and pass it to the
      // copy function
      $(".js-copy").click(function () {
        var text = $(this).attr("data-copy");
        var el = $(this);
        copyToClipboard(text, el);
      });
    });
  },
};
</script>
<style>
.sWaiting {
  background-image: url(./../assets/Group_124.svg);
  background-size: cover;
  background-position: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  margin-top: 50px;
}

.btn-msk {
  padding: 1.375rem 1.75rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 3.25rem;
}

.tel-log {
  margin-left: 3rem !important;
}

.ketIndo {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

#ketBottom {
  display: none;
}

.b-mobile .copyPasien h4 {
  font-size: 10px !important;
}

.b-mobile .copyPasien span {
  font-size: 10px !important;
}

.b-mobile .copyPasien div {
  max-block-size: 250px !important;
}

.b-mobile .btn-msk {
  padding: 1rem 1.75rem;
  font-size: 1.2rem;
}

.b-mobile #ketBottom {
  display: block;
}

.b-mobile #ketTop {
  display: none;
}

.b-mobile .sWaiting {
  margin-top: 50px;
}

.b-mobile h4 {
  font-size: 1rem !important;
}

.b-mobile h2 {
  font-size: 1.5rem !important;
}

.b-mobile .tel-log {
  height: 30px !important;
  margin-left: 2rem !important;
  margin-right: 0.5rem !important;
}

.b-mobile .telTitle {
  font-size: 16px !important;
  margin-top: 0.5rem !important;
}

.b-mobile .ketIndo {
  margin-left: 1rem;
  margin-right: 1rem;
}

.flip-card__top[data-v-78efe7f6],
.flip-card__bottom[data-v-78efe7f6],
.flip-card__back-bottom[data-v-78efe7f6],
.flip-card__back[data-v-78efe7f6]::before,
.flip-card__back[data-v-78efe7f6]::after {
  color: #0062cc !important;
  background: #e9ecef !important;
  border-top: solid 1px #ced4da !important;
}

.flip-clock__slot {
  display: none !important;
}

.footer-tnc {
  position: absolute;
  bottom: 30px;
  left: 10px;
}
.absolute-top-0-right-0 {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
}

.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.status-circle.status-idle {
  border: 2px solid darkgrey;
  background-color: white;
}

.status-circle.status-wsconnecting {
  border: 2px solid #9ea7ad;
  background-color: #9ea7ad;
}

.status-circle.status-wsconnfailed {
  border: 2px solid #ff3838;
  background-color: #ff3838;
}

.status-circle.status-wsconnsuccess {
  border: 2px solid #2dccff;
  background-color: #2dccff;
}

.status-circle.status-waitingready {
  border: 2px solid #ffb302;
  background-color: #ffb302;
}

.status-circle.status-allready {
  border: 2px solid #56f000;
  background-color: #56f000;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink-me {
  animation: blinker 1s linear infinite;
}
</style>
